import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar.js";
import Cursor from "./components/Cursor/Cursor";
// import About from './components/About/About';
import Work from "./components/Work/Work";
import { Helmet } from "react-helmet";
import Modal from "./components/Footer/Modal";
import Project1 from "./components/Work/pages/Project1";
import Project2 from "./components/Work/pages/Project2";
import Project3 from "./components/Work/pages/Project3";
import NoPage from "./components/Work/pages/NoPage";
import Hero from "./components/Hero.js";
import StarBackground from "./components/StarBackground.js";
// import StarsCanvas from './components/StarsCanvas.js';

function Home() {
  window.addEventListener("scroll", reveal);

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowheight = window.innerHeight;
      var revealtop = reveals[i].getBoundingClientRect().top;
      var revealpointt = 150;

      if (revealtop < windowheight - revealpointt) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  return (
    <div>
      {/* <h1>Hello World This is the main page</h1>
      <Link to="/Project1">Project1</Link> <br></br>
      <Link to="/Project2">Project2</Link> <br></br>
      <Link to="/Project3">Project3</Link> <br></br> */}

      <div>
        <StarBackground />
        <Cursor />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Athira T</title>
          <link rel="canonical" href="http://athirathottadi.com" />
        </Helmet>
        <Navbar />
        <Hero />

        {/* <About/> */}
        <Work />
        <Modal />
        {/* <Hero/> */}
      </div>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Project1" element={<Project1 />} />
        <Route path="/Project2" element={<Project2 />} />
        <Route path="/Project3" element={<Project3 />} />
        <Route path="/Nopage" element={<NoPage />} />
      </Routes>
      {/* <a href = "/Project1" target='_blank' rel='noopener noreferrer'>Project1</a> */}
    </BrowserRouter>
  );
}

export default App;
