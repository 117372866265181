import React from "react";
import Cursor from "./Cursor";
import ModalImage from "react-modal-image";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import imageSrc from "./pics/0.jpg";
import imageSrc1 from "./pics/1.jpg";
import imageSrc2 from "./pics/2.jpg";
import imageSrc221 from "./pics/2.1.jpg";
import imageSrc3 from "./pics/3.jpg";
import imageSrc4 from "./pics/4.jpg";
import imageSrc5 from "./pics/5.jpg";
import imageSrc6 from "./pics/6.jpg";
import imageSrc7 from "./pics/7.jpg";
import imageSrc8 from "./pics/8.jpg";
import imageSrc81 from "./pics/8.1.png";
import imageSrc9 from "./pics/9.jpg";
import imageSrc10 from "./pics/10.jpg";
import imageSrc11 from "./pics/11.jpg";
import imageSrc112 from "./pics/112.png";
import imageSrc12 from "./pics/12.jpg";
import imageSrc122 from "./pics/122.png";
import imageSrc13 from "./pics/13.jpg";
import imageSrc132 from "./pics/132.png";
import imageSrc14 from "./pics/14.jpg";
import imageSrc15 from "./pics/15.jpg";
import imageSrc16 from "./pics/16.jpg";
import imageSrc17 from "./pics/17.jpg";
import imageSrc18 from "./pics/18.jpg"
import imageSrc19 from "./pics/19.jpg";
import imageSrc20 from "./pics/20.jpg";
import imageSrc21 from "./pics/21.jpg";
import imageSrc22 from "./pics/22.jpg";
import imageSrc23 from "./pics/23.jpg";
import imageSrc24 from "./pics/24.jpg";
import imageSrc25 from "./pics/25.jpg";
import imageSrc26 from "./pics/26.jpg";
import imageSrc27 from "./pics/27.jpg";
import imageSrc28 from "./pics/28.jpg";
import "./Project1.css";
function Project1() {
  return (
    <div>
    <Cursor/>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Intelligent Vegetation Management System</title>
        <link rel="canonical" href="http://athirathottadi.com" />
      </Helmet>
      <Header />
      <div class="container">

        <div class="box">
          <img src={imageSrc} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc1} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc2} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc221} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc3} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc4} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc5} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc6} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc7} alt="" />
        </div>
        <div class="box1">
        <ModalImage
  small={imageSrc8}
  large={imageSrc81}
  alt="Vegetation Manager"
  hideDownloadButton={true}
/>;

        </div>
        <div class="box">
          <img src={imageSrc9} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc10} alt="" />
        </div>
        <div class="box1">
        <ModalImage
  small={imageSrc11}
  large={imageSrc112}
  alt="High Level Flows"
/>;
        </div>
        <div class="box1">
        <ModalImage
  small={imageSrc12}
  large={imageSrc122}
  alt="Ideation"
/>;
        
        </div>
        <div class="box1">
        <ModalImage
  small={imageSrc13}
  large={imageSrc132}
  alt="Vegetation Manager"
/>;
         
        </div>
        <div class="box">
          <img src={imageSrc14} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc15} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc16} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc17} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc18} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc19} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc20} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc21} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc22} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc23} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc24} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc25} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc26} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc27} alt="" />
        </div>
        <div class="box">
          <img src={imageSrc28} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Project1;
