import React, { useEffect } from 'react';
import './Work.css'; // Import the CSS file
import img from './Work1.png';
import img2 from './Sass1.png';
import img3 from './Work3.png';
import img4 from './Vector.svg';

const selectedWorksData = [
  {
    title: "INTELLIGENT VEGETATION MANAGEMENT SYSTEM",
    description: (
      <>
        Optimising <span style={{ fontWeight: 700 }}>Vegetation management</span> efforts, enhance infrastructure reliability of the <span style={{ fontWeight: 700 }}>Electric Power Grid</span> for the Electric Utilities.⚡️
      </>
    ),
    techStack:
    (
      <>
        <span style={{ color: "#FF9B03" }}>Energy </span>| UX Design | System Design
      </>
    ),
    imageUrl: img,
    projectLink: "/Project1",
  },
  {
    title: "PRE COMMERCIAL LAUNCH ANALYTICS MODULE",
    description: (
      <>
        <span style={{ fontWeight: 700 }}>SAAS Product</span> for <span style={{ fontWeight: 700}}> Business stakeholders, product managers, and marketing teams </span> involved in pre-commercial launch activities of a new  <span style={{ fontWeight: 700 }}> pharmaceutical product</span>. 🧬
      </>
    ),
    techStack:
    (
      <>
        <span style={{ color: "#47D5E0" }}>Life Science </span>| SAAS | Big Data | Data Analytics | Product Design 
      </>

    ) ,
    imageUrl: img2,
    projectLink: "/Project2",
  },
  {
    title: "CARBON MINUS: CARBON ACCOUNTING ",
    description: (
      <>
        Empower businesses to accurately <span style={{ fontWeight: 700 }}>measure, manage, and reduce</span> their <span style={{ fontWeight: 700 }}>carbon emission</span>. ♻️
      </>
    ),
    techStack: (<>
      <span style = {{color: "#00C108"}}>GHG </span>| SAAS Project
    </>
    )
    ,
    imageUrl: img3,
    projectLink: "/Project3",
  },
];

const Work = () => {
  useEffect(() => {
    const handleMouseMove = (event) => {
      const rotateX = -(window.innerWidth / 2 - event.pageX) / 90; // Adjusted value for sensitivity
      const rotateY = (window.innerHeight / 2 - event.pageY) / 95; // Adjusted value for sensitivity
      
      document.querySelectorAll(".project-box").forEach((project) => {
        project.style.transform = `rotateY(${rotateX}deg) rotateX(${rotateY}deg)`;
      });
    };
  
    document.getElementById("work").addEventListener("mousemove", handleMouseMove);
  
    return () => {
      document.getElementById("work").removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  

  return (
    <div id="work">
      <p id="Selected" style={{ color: "white", fontSize: '45px' }}>Selected Works</p>
      <div className="selected-works-container">
        {selectedWorksData.map((project, index) => (
          <div
            className={`project-box reveal ${index % 2 === 0 ? "odd" : "even"}`}
            key={index}
          >
            <div className="project-image">
              <img src={project.imageUrl} alt={project.title} />
            </div>
            <div className="project-details">
              <h1 style={{ color: 'white', fontSize: 16, fontFamily: "Work Sans" }}>{project.title}</h1>
              <p style={{ color: 'white', fontSize: 30, fontFamily: "Work Sans" }}>{project.description}</p>
              <p style={{ color: 'white', fontSize: 18, fontFamily: "Work Sans" }}>{project.techStack}</p>
              <a
                href={project.projectLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                VIEW WORK
                <img src={img4} alt="arrow" />
              </a>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
