import React from 'react'
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Cursor from "./Cursor";
import Header from "../components/Header";
import "./Project3.css"
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';


function Project3() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(() => {
    // Open the modal when the component mounts
    handleShow();
  }, []);

  function closeWindow() {
    window.close();
}
  return (
    <div>
        <Cursor/>
        <Header/>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Carbon Minus</title>
            </Helmet>
        <div class="container1">
        <div class="box3"></div>
        <div class="box3"></div>
        <div class="box3"></div>
        <div class="box3"></div>
        <div class="box3"></div>
    </div>
    <>
      <Button variant="primary" onClick={handleShow}>
        
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        <Modal.Header >
          <Modal.Title>Carbon Minus Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please enter the hash key
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label></Form.Label>
              <Form.Control
                type="email"
                placeholder="xxxxxxxxxx"
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeWindow}>
          go back
          </Button>
          <Button variant="primary">Log in</Button>
        </Modal.Footer>
      </Modal>
    </>
    </div>
  );
}

export default Project3