"use client";

import React, { useState, useRef, Suspense } from "react";
import "./Star.css";
import { Canvas, useFrame } from "@react-three/fiber";
import { Points, PointMaterial, Preload } from "@react-three/drei";
// @ts-ignore
import * as random from "maath/random/dist/maath-random.esm";

const StarBackground = (props) => {
  const ref = useRef();
  const [sphere] = useState(() =>
    random.inSphere(new Float32Array(5000), { radius: 1.2 })
  );

  useFrame((state, delta) => {
    ref.current.rotation.x -= delta * 0.01;
    ref.current.rotation.y -= delta * 0.01;
  });

  return (
    <group position={[0, 0, 0]} rotation={[Math.PI / 4, 0 , 0]}>
      <Points ref={ref} positions={sphere} stride={3} frustumCulled {...props}>
        <PointMaterial
          transparent
          color="$fff"
          size={0.0025}
          sizeAttenuation={true}
          depthWrite={true}
        />
      </Points>
    </group>
  );
};

const StarsCanvas = () => (
  <div className="fullscreen-container">
    <Canvas camera={{ position: [0, 0, 0.1] }}>
      <Suspense fallback={null}>
        <Preload all />
        <StarBackground />
      </Suspense>
    </Canvas>
  </div>
);

export default StarsCanvas;
