import React from "react";
import { motion } from "framer-motion";
// import { slideInFromLeft} from "../../public/utils/motion";
// import { SparklesIcon } from "@heroicons/react/16/solid";
import "./Hero1.css";
const HeroContent = () => {
  return (
    <motion.div
      initial="Hidden"
      animate="visible"
      className="containerz1"
    >
      <motion.div
        // variants={slideInFromLeft(0.5)}
        className="containerz"
      >
        <p>
          Hi, I’m <span className="bro">Athira</span>, Designer. passionate about creating digital experiences that are intuitive and accessible for everyone.
        </p>
      </motion.div>
    </motion.div>
  );
};

export default HeroContent;
