import React, { useState, useEffect } from "react";
import './Navbar.css';




function Navbar(){
    const [isSticky, setIsSticky] = useState(false);
  
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);



    return (
 
        <nav className={`navbar ${isSticky ? 'sticky' : ''}`}>
            <div className='logo'>athira</div>
            <ul className='nav-links'>
                <li><a href='#About'>HOME</a></li>
                <li><a href='#work'>WORK</a></li>
                <li><a href='#contact'>CONTACT</a></li>
                <li><a href='Athira.pdf' download="Athira.pdf">RESUME</a></li>
            </ul>
        </nav>

    );
}

export default Navbar;