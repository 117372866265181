import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

const YoutubeEmbed = ({ embedId }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger only once when the video comes into view
    rootMargin: '0px 0px 100px 0px', // Adjust this margin as needed
  });

  useEffect(() => {
    if (inView) {
      // If the video is in view, autoplay it
      const iframe = document.querySelector(`#youtube-embed-${embedId}`);
      if (iframe) {
        iframe.src += '?autoplay=1'; // Append autoplay parameter to the URL
      }
    }
  }, [inView, embedId]);

  return (
    <div className="video-responsive" ref={ref}>
      <iframe
        id={`youtube-embed-${embedId}`}
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
