import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import './Header.css';

function Header() {
    const [isSticky, setIsSticky] = useState(false);
  
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    
    function closeWindow() {
        window.close();
    }

  return (
    <div>
      <nav className={`navbar1 ${isSticky ? 'sticky' : ''}`}>
            <div className='logo1'>athira</div>
            {isSticky && <div className="placeholder1" />}
            <ul className='nav-links1'>
                <li>{" "} <Link to="#" onClick={closeWindow}>X</Link></li>
            </ul>
        </nav>
    </div>
  )
}

export default Header