import React from "react";
import HeroContent from "./HeroContent";
import "./Hero.css";
const Hero = () => {
  return (
    <div className="container2">
      <video
        autoPlay
        muted
        loop
        className="container4"
      >
        <source src="/blackhole(1).webm" type="video/webm" />
      </video>
      <HeroContent/>
    </div>
  );
};

export default Hero;
