import React, { useState, useRef} from "react";
import "./Modal.css";
import emailjs from '@emailjs/browser';
import "./ContactPage.css"; // Import your CSS file for styling
import EmailSVG from "./email.svg"; // Import your SVG image for email
import LinkedInSVG from "./linkedIn.svg"; 
import "./ContactUs"


export default function Modal() {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if(modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_aswsngl', 'template_tveb7gl', form.current, 'l1zl-fhGQXvO0gXsJ')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <>
      <div class = "boxes" id = "contact">
    <footer class="footer">
    <ul class="menu">
      <p style = {{color: "grey", fontFamily:"Sans-Serif", fontSize:58}}>Get <span style = {{color: "white", fontSize:62}}>in Touch.</span></p>
    </ul>
    <p id = "line" style = {{textAlign: "center"}}>So that we can talk more about ...</p>
    <div style={{ textAlign: "center" }}>
          <button onClick={toggleModal} className="btn-modal">
            <img src={EmailSVG} alt="Email" />
          </button>
          <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFr0wO7AflgIAAAAYpgGcBYa6DJURUa3mH3RodVKu_8l-Tow319Dougw73BCFG2XyX_UgJm0YOFhZJ7Wj_Yclsjp8YTfcBLvBfjO77EWFWImlggmVP-o2v5VIpqrlfIr9bGv1Y=&original_referer=&sessionRedirect=https%3A%2F%2Fin.linkedin.com%2Fin%2Fathiraat%3Ftrk%3Dpublic_profile_samename-profile" target="_blank" rel="noopener noreferrer">
          <button  className="btn-modal" onclick="window.open('http://google.com','_blank')">
            <img src={LinkedInSVG} alt="LinkedIn" />
          </button>
          </a>
        </div>
  </footer>
      
</div>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
          <div class="form-container">
      <form class="form" ref = {form} onSubmit = {sendEmail}>
        <div class="form-group">
          <label for="Name">Name</label>
          <input type="text" id="email" name="from_name" required=""/>
          <label for="email">Email</label>
          <input type="text" id="email" name="email" required=""/>
        </div>
        <div class="form-group">
          <label for="textarea">How Can We Help You?</label>
          <textarea name="message" id="textarea" rows="10" cols="50" required=""></textarea>
        </div>
        <input type="submit" value="Send" />
      </form>
    </div>
            <button className="close-modal" onClick={toggleModal}>
              CLOSE
            </button>
          </div>
        </div>
      )}
    </>
  );
}