import './Cursor.css';
import React, { useEffect, useState } from 'react';


const Cursor =  () => {

    const [position, setPosition] = useState({x: 0, y: 0});

        useEffect(() => {
            addEventListeners();
            return () => removeEventListeners(); 
            // eslint-disable-next-line 
        }, []);

        const addEventListeners = () => {
            document.addEventListener("mousemove", onMouseMove);
        };

        const removeEventListeners = () => {
            document.removeEventListener("mousemove", onMouseMove);
        };

        const onMouseMove = (e) => {
            setPosition({x: e.pageX, y: e.pageY});
        };

   console.log(position.x)

         return (
           <div>
             <div style={{
                transform: `translate(${position.x}px, ${position.y}px)`}} className="cursor1"></div>
             <div style={{
                transform: `translate(${position.x}px, ${position.y}px)`}} className="cursor1-follower"></div>
           </div>
         )

 }

export default Cursor;