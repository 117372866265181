// import React, { useState, useEffect } from "react";

import Header from "../components/Header";
import Cursor from "./Cursor";
import { Helmet } from 'react-helmet';
import img0 from "./images/1.jpg";
import img1 from "./images/2.png";
import img2 from "./images/3.png";
import img3 from "./images/4.png";
import img4 from "./images/5.png";
import img5 from "./images/7.png";
import 'bootstrap/dist/css/bootstrap.min.css';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import Modal from 'react-bootstrap/Modal';
import "./Project2.css";
import YoutubeEmbed from "./youtubeEmbed";

function Project2() {
  // const [show, setShow] = useState(false);
  // const [hashKey, setHashKey] = useState('');
  //
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  //
  // useEffect(() => {
  //   handleShow();
  // }, []);
  //
  // function closeWindow() {
  //   window.close();
  // }
  //
  // const handleLogin = () => {
  //   // Replace 'your-predefined-password' with the actual password you want to check
  //   const predefinedPassword = 'athirat@gmail.com';
  //   if (hashKey === predefinedPassword) {
  //     alert('Logged in successfully');
  //     handleClose();
  //   } else {
  //     alert('Invalid hash key');
  //   }
  // };

  return (
    <div>
      <Cursor />
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>PRE COMMERCIAL LAUNCH ANALYTICS MODULE</title>
        <link rel="canonical" href="http://athirathottadi.com" />
      </Helmet>
      <div className="container">
        <div className="box2">
          <img src={img0} alt="" />
        </div>
        <div className="box2">
          <img src={img1} alt="" />
        </div>
        <div className="box2">
          <img src={img2} alt="" />
        </div>
        <div className="box2">
          <img src={img3} alt="" />
        </div>
        <div className="box2">
          <img src={img4} alt="" />
        </div>
        <div className="box2">
          <img src={img5} alt="" />
        </div>
        <div className="App">
          <h3>Certain aspects of this project are best discussed in a more detailed, offline setting. For an initial overview of the application's capabilities, please view the accompanying demonstration video below.</h3>
          <YoutubeEmbed embedId="mJTt-xrWCLA" />
        </div>
      </div>

      {/*<Button variant="primary" onClick={handleShow}>*/}
      {/*  Open Modal*/}
      {/*</Button>*/}

      {/*<Modal*/}
      {/*  show={show}*/}
      {/*  onHide={handleClose}*/}
      {/*  backdrop="static"*/}
      {/*  keyboard={false}*/}
      {/*  aria-labelledby="contained-modal-title-vcenter"*/}
      {/*  centered*/}
      {/*>*/}
      {/*  <Modal.Header>*/}
      {/*    <Modal.Title>Pre-Commercial Launch Analytics Module</Modal.Title>*/}
      {/*  </Modal.Header>*/}
      {/*  <Modal.Body>*/}
      {/*    Please enter the hash key*/}
      {/*    <Form>*/}
      {/*      <Form.Group className="mb-3" controlId="formHashKey">*/}
      {/*        <Form.Label></Form.Label>*/}
      {/*        <Form.Control*/}
      {/*          type="password"*/}
      {/*          placeholder="Enter hash key"*/}
      {/*          value={hashKey}*/}
      {/*          onChange={(e) => setHashKey(e.target.value)}*/}
      {/*          autoFocus*/}
      {/*        />*/}
      {/*      </Form.Group>*/}
      {/*    </Form>*/}
      {/*  </Modal.Body>*/}
      {/*  <Modal.Footer>*/}
      {/*    <Button variant="secondary" onClick={closeWindow}>*/}
      {/*      Go back*/}
      {/*    </Button>*/}
      {/*    <Button variant="primary" onClick={handleLogin}>*/}
      {/*      Log in*/}
      {/*    </Button>*/}
      {/*  </Modal.Footer>*/}
      {/*</Modal>*/}
    </div>
  );
}

export default Project2;
